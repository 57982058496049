
/**
 * functionIDs holds the string values of all the http callable functions
 * that live on our backend. Instead of writing sting literals into the
 * httpCallable function, use this object and its nested properties.
 */
export const functionIDs = {
    users: {
        initializeNewCustomer: "users-initializeNewCustomer",
        setCustomUserClaims: "users-setCustomUserClaims"
    },
    coverage: {
        purchaseCoverage: "coverage-purchaseCoverage"
    },
    coverageItems: {
        addCoverageItem: "coverageItems-addCoverageItem",
        addCoverageItemVariation: "coverageItems-addCoverageItemVariation",
        setActiveCoverageItemVariation: "coverageItems-setActiveCoverageItemVariation"
    },
    purchases: {
        getUserCoveragePurchasesDetails: "purchases-getUserCoveragePurchasesDetails"
    },
    recovery: {

    }
}