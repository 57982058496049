import navbarStyles from './NavBar.comp.scss'
import { DASHBOARD_URL, HOMEPAGE_URL, LOGIN_URL, SIGN_UP_URL, MANAGE_VEHICLES_URL, 
        PROFILE_URL, PURCHASE_COVERAGE_URL, VEHICLE_RECOVERY_URL,
        SERVICES_URL, OPERATOR_REQUEST_LIST_URL, GETTING_STARTED_URL,
        TERMS_SERVICE_URL, CONTACT_US_URL, ABOUT_US_URL, PRIVACY_POLICY_URL} from "../shared/constants/urls.js";
import PERMISSIONS from "../shared/enums/Permissions.js"
import { hasPermissions } from "../data-access/PermissionsDataAccess.js";

const NAVBAR_BREAKPOINT = 900;

const navbarHtml = /* HTML */`
<nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
  <div class="container is-widescreen">
    <div id="navbar-brand" class="navbar-brand">
        <a class="navbar-item" href="${HOMEPAGE_URL}">
            <img src="img/NavbarLogo.png" alt="Glamis911.com">
        </a>

        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <p class="burger-text">Menu</p>
        </a>
    </div>
    <div id="navbarMenu" class="navbar-menu">
        <div class="navbar-start">
            <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link" data-cy="navbar-my-coverage-hover-link">
                    Services
                </a>
                <div class="navbar-dropdown">
                    <a class="navbar-item" href="${HOMEPAGE_URL}" data-cy="navbar-homepage-link">
                        Home
                    </a>
                    <a class="navbar-item" href="${SERVICES_URL}" data-cy="navbar-vehicle-coverage-link">
                        Vehicle Coverage
                    </a>
                    <a class="navbar-item" href="${ABOUT_US_URL}" data-cy="navbar-about-us-link">
                        About Us
                    </a>
                    <a class="navbar-item" href="${CONTACT_US_URL}" data-cy="navbar-contact-us-link">
                        Contact Us
                    </a>
                </div>
            </div>
            <div class="navbar-item has-dropdown is-hoverable logged-in is-hidden">
                <a class="navbar-link" data-cy="navbar-my-coverage-hover-link">
                    My Coverage
                </a>
                <div class="navbar-dropdown">
                    <a class="navbar-item" href="${MANAGE_VEHICLES_URL}" data-cy="navbar-manage-vehicles-link">
                        Manage Vehicles
                    </a>
                    <a class="navbar-item" href="${DASHBOARD_URL}" data-cy="navbar-coverage-dashboard-link">
                        Coverage Dashboard
                    </a>
                    <a class="navbar-item" href="${PURCHASE_COVERAGE_URL}" data-cy="navbar-purchase-coverage-link">
                        Purchase Coverage
                    </a>
                    <a class="navbar-item" href="${VEHICLE_RECOVERY_URL}" data-cy="navbar-vehicle-recovery-link">
                        Request Rescue
                    </a>
                    <a class="navbar-item" href="${GETTING_STARTED_URL}" data-cy="navbar-getting-started-link">
                        Getting Started
                    </a>
                </div>
            </div>
            <div class="navbar-item has-dropdown is-hoverable operator-view is-hidden">
                <a class="navbar-link" data-cy="navbar-operator-hover-link">
                    Operator Views
                </a>
                <div class="navbar-dropdown">
                    <a class="navbar-item" href="${OPERATOR_REQUEST_LIST_URL}" data-cy="navbar-rescue-requests-link">
                        Recovery Requests
                    </a>
                </div>
            </div>
        </div>
        <div class="navbar-end">
            <div class="navbar-item logged-out is-hidden">
                <div class="field is-grouped mr-4">
                    <p class="control my-1">
                        <button class="button is-primary is-normal"
                            onclick ="window.location.href = '${LOGIN_URL}'" data-cy="navbar-login-button">
                            <strong>Login</strong>
                        </button>
                    </p>
                    <p class="control my-1">
                        <button class="button is-primary is-normal is-outlined"
                            onclick ="window.location.href = '${SIGN_UP_URL}'" data-cy="navbar-sign-up-button">
                            <strong>Sign Up</strong>
                        </button>
                    </p>
                </div>
            </div>
            <div class="navbar-item has-dropdown is-hoverable logged-in is-hidden">
                <a id="navbar-greeting" class="navbar-link" data-cy="navbar-greeting-hover-link"></a>
                <div class="navbar-dropdown is-right">
                    <a class="navbar-item" href="${PROFILE_URL}" data-cy="navbar-my-account-link">
                        My Account
                    </a>
                    <a class="navbar-item" href="${TERMS_SERVICE_URL}" data-cy="navbar-terms-of-service-link">
                        Terms of Service
                    </a>
                    <a class="navbar-item" href="${PRIVACY_POLICY_URL}" data-cy="navbar-privacy-policy-link">
                        Privacy Policy
                    </a>
                    <hr class="navbar-divider">
                    <div class="navbar-item">
                        <button class="button is-dark is-normal" id="logout-btn" data-cy="navbar-sign-out-button">
                            Sign Out
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</nav>
`;

function hideElement(element) {
    if (!element.classList.contains("is-hidden")) {
        element.classList.add("is-hidden");
    }
}

function showElement(element) {
    if (element.classList.contains("is-hidden")) {
        element.classList.remove("is-hidden");
    }
}

function activateElement(element) {
    if (!element.classList.contains("is-active")) {
        element.classList.add("is-active");
    }
}

function deactivateElement(element) {
    if (element.classList.contains("is-active")) {
        element.classList.remove("is-active");
    }
}

class NavBar extends HTMLElement {

    constructor() {
        super();
        this._user = null;
        let shadowRoot = this.attachShadow({mode: "open"});

        shadowRoot.innerHTML = navbarHtml;

        const style = document.createElement('style');
        style.textContent = navbarStyles;
        shadowRoot.appendChild(style);

        this.activeDropdownMenu = null;
    }

    connectedCallback() {
        // // Filter the Links in the navbar based on user's authentication status
        // this.persistentLinks = this.shadowRoot.querySelectorAll(".persistent");
        this.loggedInLinks = this.shadowRoot.querySelectorAll(".logged-in");
        this.loggedOutLinks = this.shadowRoot.querySelectorAll(".logged-out");
        this.operatorLinks = this.shadowRoot.querySelectorAll(".operator-view");
        this.filterNavbarLinks();

        // // Add event listener to the logout button element
        this.logoutButton = this.shadowRoot.querySelector('#logout-btn');
        this.logoutButton.onclick = this.dispatchLogoutEvent.bind(this);

        // Get the "navbar-burger" element
        const navbarBurger = this.shadowRoot.querySelector('.navbar-burger');
        const navbarMenu = this.shadowRoot.querySelector('.navbar-menu');
      
        // Check if there are any navbar burgers
        if (navbarBurger) {
            // Add a click event on each of them
            navbarBurger.addEventListener('click', () => {        
                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                navbarBurger.classList.toggle('is-active');
                navbarMenu.classList.toggle('is-active');
            });
        }

        // Add OnClick Event listeners
        this.dropdownSubmenus = this.shadowRoot.querySelectorAll(".navbar-item.has-dropdown");
        this.activeDropdownMenu = null;
        this.addMobileMenuEventListeners();
    }

    set user(user) {
        this._user = user;
        this.filterNavbarLinks();

        if (user) {
            // add user data to navbar
            let greetingElem = this.shadowRoot.querySelector('#navbar-greeting');
            greetingElem.innerHTML = `Account: ${getFirstName(user.displayName)}`;
        }
    }

    get user() {
        return this._user;
    }

    async filterNavbarLinks() {
        if (this.user) {
            this.loggedInLinks.forEach(element => {
                showElement(element);
            });
            this.loggedOutLinks.forEach(element => {
                hideElement(element);
            });
            let isOperator = await hasPermissions(PERMISSIONS.OPERATOR);
            if (isOperator) {
                this.operatorLinks.forEach(element => {
                    showElement(element);
                });
            }
        } else { // user is null
            this.loggedOutLinks.forEach(element => {
                showElement(element);
            });
            this.loggedInLinks.forEach(element => {
                hideElement(element);
            });
            this.operatorLinks.forEach(element => {
                hideElement(element);
            });
        }
    }

    dispatchLogoutEvent() {
        this.dispatchEvent(new CustomEvent('logout', {bubbles: true}));
    }

    addMobileMenuEventListeners() {
        
        this.dropdownSubmenus.forEach(element => {
            element.addEventListener('click', () => {
                this.mobileMenuClickEvent(element)       
            });
        });        
    }

    mobileMenuClickEvent(navBarElement) {
        console.debug("mobileMenuClick Event");
        // Only do anything when we're working with Mobile Menu
        if (window.screen.width < NAVBAR_BREAKPOINT) {
            console.debug("Meets screen requirement");
            
            // If there is currently something else open 
            // => need to close it before opening another
            if (this.activeDropdownMenu) {
                let dropDown = this.activeDropdownMenu.querySelector(".navbar-dropdown");
                deactivateElement(dropDown);
            }

            // Open the new submenu if it wasn't already open
            if (this.activeDropdownMenu != navBarElement) {
                let dropDown = navBarElement.querySelector(".navbar-dropdown");
                activateElement(dropDown);
                this.activeDropdownMenu = navBarElement;
            } else { // close the menu that was clicked on
                this.activeDropdownMenu = null;
            }
        }
    }
}

if (customElements.get("nav-bar") === undefined) {
    customElements.define("nav-bar", NavBar);
}

/**
 * Grab the first name from string by assuming that the first name is the first
 * token before the first space character.
 * @param {string} displayName 
 * @returns {string}
 */

function getFirstName(displayName) {
    let tokens = displayName.split(' ');
    return tokens[0];
}